import axios from "axios";
import queryString from "query-string";
import { toast } from "react-toastify";

// Constants
import { EMPTY_OBJECT } from "../constants/base.constants";
import { BASE_URL } from "../constants/services";

// Utils
import { getDataFromResponse } from "../utils";

const headers = {
  headers: {
    authorization: `Bearer ${window.localStorage.getItem("token")}`,
  },
};

const fetchCountries = () => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/config/getAllCountry`,
  });
  return axios
    .get(url, headers)
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

const createCountry = (payload = EMPTY_OBJECT) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/config/addCountry`,
  });
  return axios
    .post(url, payload, headers)
    .then((response) => response.data)
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

const updateCountry = (id, payload = EMPTY_OBJECT) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/config/editCountry`,
  });
  return axios
    .put(url, payload, headers)
    .then((response) => response.data)
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

// const deleteCompany = (id) => {
//   const url = queryString.stringifyUrl({
//     url: `${BASE_URL}/company/${id}`,
//   });
//   return axios
//     .delete(url, headers)
//     .then((response) => response.data)
//     .catch((error) => {
//       toast.error(error.response.data?.error);
//     });
// };

export default {
  fetchCountries,
  updateCountry,
  createCountry
};
