
// Components
import Button, { BUTTON_TYPES } from "../../../components/atoms/button";
import { EditOutlined, DeleteOutlined } from "../../../components/atoms/icon";
// Styles
import styles from "../country.module.scss";
const createColumns = ({ onEdit, onDelete,  }) => {
  return [
    {
      title: "Country Name",
      sorter: true,
      dataIndex: 'countryName',
      render: (text, countryDetail) => {
        return `${countryDetail.countryName}`;
      },
    },
    {
      title: "Country Language",
      sorter: true,
      dataIndex: 'countryLanguage',
      render: (text, countryDetail) => {
        return `${countryDetail.countryLanguage}`;
      },
    },
    {
      title: "Country Currency",
      sorter: true,
      dataIndex: 'countryCurrency',
      render: (text, countryDetail) => {
        return `${countryDetail.currency}`;
      },
    },
    {
      title: "Vat Tax",
      sorter: true,
      dataIndex: 'vatTax',
      render: (text, countryDetail) => {
        return `${countryDetail.countryVatPercent}`;
      },
    },
    {
      title: "Goods Tax",
      sorter: true,
      dataIndex: 'goodTax',
      render: (text, countryDetail) => {
        return countryDetail.countryGoodPercent;
      },
    },
    
    {
      title: "Actions",
      render: (text, businessAccountDetails) => {
        return (
          <>
            <Button
              type={BUTTON_TYPES.LINK}
              onClick={(event) => {
                onEdit(businessAccountDetails);
              }}
              className={styles.icon}
            >
              <EditOutlined />
            </Button>
            <Button
              disabled
              type={BUTTON_TYPES.LINK}
              onClick={(event) => {
                onDelete(businessAccountDetails);
              }}
              className={`${styles.icon} ${styles.iconDanger}`}
            >
              <DeleteOutlined />
            </Button>
          </>
        );
      },
    },
  ];
};

export { createColumns };
