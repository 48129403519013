const TEXT = "text";
const TEXTAREA = "textarea";
const EMAIL = "email";
const PASSWORD = "password";
const NUMBER= 'number'

const TYPES = {
  TEXT,
  TEXTAREA,
  EMAIL,
  PASSWORD,
  NUMBER
};

export default TYPES;
