import React, { useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

// Components
import AppSkeleton from "../components/organisms/appSkeleton";
import Dashboard from "../pages/dashboard";
import RolesPage from "../pages/roles";
import SportsPage from "../pages/sports";
import SubAdminsPage from "../pages/subAdmins";
import UsersPage from "../pages/users";
import FreelancersPage from "../pages/freelancers";
import VenuesPage from "../pages/venues";
import ProfilePage from "../pages/profile";
import NotificationPage from "../pages/notification";
import CompaniesPage from "../pages/companies";
import PromotionPage from "../pages/promotion/Promotion"
import BusinessAccounts from "../pages/businessAccounts";
import Payments from "../pages/payments";
import Posts from "../pages/posts";
import Chats from "../pages/chats";
import Packages from "../pages/packages";
import Activities from "../pages/activities";
import Configuration from "../pages/configuration";
import Transaction from "../pages/transaction";
import {
  PaymentsHistory,
  UnsettledSales,
  PaymentDetails,
  PartnerTransactions
} from "../pages/payments/components";
import Sales from "../pages/sales/Sales";

// Constants
import { MODULES } from "../constants/modules";
import ROUTES from "../constants/routes";
import Country from "../pages/countryList/index";

function SecureRoutes() {
  const navigate = useNavigate();
  const signedIn = localStorage.getItem("signedIn");

  useEffect(() => {
    if (signedIn !== "true") {
      navigate("/login");
      return ;
    }
  }, [signedIn]);

  if (signedIn !== "true") {
    return null;
  }

  // const { NODE_ENV } = process.env
  // const dev = NODE_ENV === "development"
  
  // TODO: Finalise the routes approach using modules
  return (
    <AppSkeleton>
      <Routes>
        <Route path={ROUTES[MODULES.PAYMENTS.id]} element={<Payments />}>
          <Route path={"history"} element={<PaymentsHistory />} />
          <Route path={":paymentId"} element={<PaymentDetails />} />
          <Route path={"unsettled"} element={<UnsettledSales />} />
          <Route path={"unsettled/partner/:partnerId"} element={<PartnerTransactions />} />
        </Route>
        <Route path="/" element={<Navigate to={ROUTES[MODULES.DASHBOARD.id]} />} />
        <Route path={ROUTES[MODULES.DASHBOARD.id]} element={<Dashboard />} />
        <Route path={ROUTES[MODULES.SALES.id]} element={<Sales />} />
        <Route path={ROUTES[MODULES.BUSINESS_ACCOUNTS.id]} element={<BusinessAccounts />} />
        <Route path={ROUTES[MODULES.SPORTS.id]} element={<SportsPage />} />
        <Route path={ROUTES[MODULES.ADMINS.id]} element={<SubAdminsPage />} />
        <Route path={ROUTES[MODULES.USERS.id]} element={<UsersPage />} />
        <Route path={ROUTES[MODULES.COUNTRY.id]} element={<Country />} />
        <Route path={ROUTES[MODULES.FREELANCERS.id]} element={<FreelancersPage />} />
        <Route path={ROUTES[MODULES.VENUES.id]} element={<VenuesPage />} />
        <Route path={ROUTES[MODULES.ROLES.id]} element={<RolesPage />} />
        <Route path={ROUTES[MODULES.PROFILE.id]} element={<ProfilePage />} />
        <Route path={ROUTES[MODULES.NOTIFICATION.id]} element={<NotificationPage />} />
        <Route path={ROUTES[MODULES.COMPANIES.id]} element={<CompaniesPage />} />
        <Route path={ROUTES[MODULES.PROMOTION.id]} element={<PromotionPage />} />
        <Route path={ROUTES[MODULES.POSTS.id]} element={<Posts />} />
        <Route path={ROUTES[MODULES.POSTS.id] + `/:userid`} element={<Posts />} />
        <Route path={ROUTES[MODULES.PACKAGES.id]} element={<Packages />} />
        <Route path={ROUTES[MODULES.TRANSACTION.id]} element={<Transaction />} />
        <Route path={ROUTES[MODULES.CHATS.id]} element={<Chats />} />
        <Route path={ROUTES[MODULES.ACTIVITIES.id]} element={<Activities />} />
        <Route path={ROUTES[MODULES.CONFIGS.id]} element={<Configuration />} />
      </Routes>
    </AppSkeleton>
  );
}

export default SecureRoutes;
