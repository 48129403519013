import React, { useState, useEffect } from "react";
// Components
import PageHeader from "../../components/molecules/pageHeader";
import { Input, Form } from "antd";
import styles from "./country.module.scss";
import { Table } from "../../components/organisms";
import { createColumns } from "./helpers/columns";
import countryService from "../../services/countryService";
import { Modal } from "antd";
import { toast } from "react-toastify";

import { Button, INPUT_TYPES } from "../../components/atoms";
function Country() {
  const [form] = Form.useForm();

  const [countryList, setCountryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editCountry, setEditCountry] = useState(null);
  const defaultValues = {
    countryName: editCountry ? editCountry?.countryName : "",
    currency: editCountry ? editCountry?.currency : "",
    countryLanguage: editCountry ? editCountry?.countryLanguage : "",
    countryVatPercent: editCountry ? `${editCountry?.countryVatPercent}` : "",
    countryGoodPercent: editCountry ? `${editCountry?.countryGoodPercent}` : "",
  };
  const handleOpenEditFreelancerForm = (freelancerDetails) => {
    setEditCountry(freelancerDetails);
    setShowModal(true);
  };
  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [form, defaultValues]);
  const handleOnInActive = () => {};

  const columns = createColumns({
    onEdit: handleOpenEditFreelancerForm,
    onInActive: handleOnInActive,
  });

  const fetchCountries = () => {
    setLoading(true);
    countryService
      .fetchCountries()
      .then((response) => {
        setCountryList(response);
      })
      .catch((err) => console.log(err, "Error"))
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchCountries();
  }, []);

  function onFinish(values) {
    if (editCountry) {
      countryService
        .updateCountry(editCountry._id, {
           ...values ,
          _id: editCountry._id,
        })
        .then((response) => {
          if (response.success) {
            toast.success("Update Successful!!");
          } else {
            toast.warning("Something went wrong while updating!");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setShowModal(false);
          fetchCountries();
        });
    } else {
      countryService
        .createCountry({ ...values })
        .then((response) => {
          toast.success("Country Added Successful!!");

          fetchCountries();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setShowModal(false);
        });
    }
  }

  return (
    <div className="container">
      {showModal && (
        <Modal
          open={showModal}
          footer={null}
          onCancel={() => {
            setShowModal(false);
            setEditCountry(null);
          }}
          okText="Create Country"
        >
          <div>
            <h3>{editCountry ? "Edit Country" : "Create Country"}</h3>
            <div style={{ marginTop: 10 }}>
              <Form
                initialValues={defaultValues}
                layout="vertical"
                form={form}
                onFinish={onFinish}
              >
                <Form.Item
                  label="Country Name"
                  name="countryName"
                  rules={[
                    { required: true, message: "Please input Country name!" },
                  ]}
                >
                  <Input
                    type={INPUT_TYPES.TEXT}
                    label={"Enter Country Name"}
                    placeholder={"Enter Country Name"}
                  />
                </Form.Item>
                <Form.Item
                  label="Country Language"
                  name="countryLanguage"
                  rules={[
                    {
                      required: true,
                      message: "Please input Country Language!",
                    },
                  ]}
                >
                  <Input
                    type={INPUT_TYPES.TEXT}
                    label={"Enter Language"}
                    placeholder={"Enter Country Language"}
                  />
                </Form.Item>
                <Form.Item
                  label="Country Currency"
                  name="currency"
                  rules={[
                    {
                      required: true,
                      message: "Please input Country Currency Name!",
                    },
                  ]}
                >
                  <Input
                    type={INPUT_TYPES.TEXT}
                    label={"Enter Currency Short Form"}
                    placeholder={"Enter Currency Short Form"}
                  />
                </Form.Item>

                <Form.Item
                  label="Country Vat Price"
                  name="countryVatPercent"
                  min={1}
                  rules={[
                    {
                      required: true,
                      message: "Please input Country Vat  Price!",
                    },
                  ]}
                >
                  <Input
                    type={INPUT_TYPES.NUMBER}
                    label={"Enter Price of Vat"}
                    min={1}
                    placeholder={"Enter Price of Vat"}
                  />
                </Form.Item>
                <Form.Item
                  label="Country Vat Price"
                  name="countryGoodPercent"
                  rules={[
                    {
                      required: true,
                      message: "Please input Country Good Vat  Price!",
                    },
                  ]}
                >
                  <Input
                    type={INPUT_TYPES.NUMBER}
                    label={"Enter Price of Good Vat"}
                    placeholder={"Enter Price Good of Vat"}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    className={styles.saveButton}
                    label={editCountry ? "Edit Country" : "Create Country"}
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  />
                </Form.Item>
              </Form>
            </div>
          </div>
        </Modal>
      )}

      <PageHeader
        title="Country"
        actions={[
          {
            title: "+ Add Country",
            handler: () => setShowModal(true),
          },
        ]}
      />
      <div className="card">
        <Table
          data={countryList}
          columns={columns}
          showSearch={false}
          searchProps={{
            placeholder: "Search Sports",
          }}
          onReload={() => fetchCountries()}
          onSearch={() => {}}
          loading={loading}
        />
      </div>
    </div>
  );
}

export default Country;
