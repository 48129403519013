import React, { useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import {  Select,  } from "antd";

// Lodash
import _noop from "lodash/noop";
import _map from "lodash/map";

// Components
import Form from "../../../../components/atoms/form";
import Button from "../../../../components/atoms/button";
import Drawer from "../../../../components/atoms/drawer";
import { Input, Password } from "../../../../components/atoms/input";
import { PickerWithType } from "../../../../components/atoms/datePicker/DatePicker";
import { toast } from "../../../../components/atoms/toaster";
import GooglePlacesAutoComplete from "../../../../components/molecules/googlePlacesAutoComplete";
import { getID } from "../../../../utils/globle";

// Reader
import freelancerReader from "../../../../readers/freelancer";

// Styles
import styles from "./createFreelancerForm.module.scss";

// Services
import freelancerService from "../../../../services/freelancerService";
import venuesService from "../../../../services/venuesService";
import countryService from "../../../../services/countryService";


function CreateFreelancerForm({
  visible,
  onClose,
  freelancerDetails,
  isEditMode,
  onReload,
  sportsList,
}) {
  const [countryList, setCountryList] = useState([]);
  useEffect(() => {
    countryService.fetchCountries().then((response) => {
      setCountryList(response);
    });
  }, []);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const freelancerID = freelancerReader.id(freelancerDetails);
  const bussiness_id = freelancerReader.bussiness_id(freelancerDetails);
  const firstName = freelancerReader.firstName(freelancerDetails);
  const lastName = freelancerReader.lastName(freelancerDetails);
  const email = freelancerReader.email(freelancerDetails);
  const userName = freelancerReader.userName(freelancerDetails);
  const contactNumber = freelancerReader.contactNumber(freelancerDetails);
  const dateOfBirth = freelancerReader.dateOfBirth(freelancerDetails);
  const status = freelancerReader.isActive(freelancerDetails);
  const favorite_sports = freelancerReader.favorite_sports(freelancerDetails);
  // const sportsListName = freelancerReader.sportsListName(freelancerDetails);
  let prevSports = favorite_sports;
  const location = freelancerReader.location(freelancerDetails);
  const latitude = freelancerReader.latitude(freelancerDetails) || 25.1044748;
  const longitude = freelancerReader.longitude(freelancerDetails) || 55.2372069;
  const packagePercentage =
    freelancerReader.packagePercentage(freelancerDetails);
  const activityPercentage =
    freelancerReader.activityPercentage(freelancerDetails);
  const slotPercentage = freelancerReader.slotPercentage(freelancerDetails);
  const countryId = freelancerReader.countryId(freelancerDetails);

  const getSportsName = (id) => {
    return sportsList.find((sport) => sport._id === id);
  };

  const renderSportsOptions = (option) => {
    return (
      <Select.Option
        key={`${option._id}@${option.name}`}
        value={`${option._id}@${option.name}`}
      >
        {option.name}
      </Select.Option>
    );
  };

  const renderCountryOptions = (option) => {
    return (
      <Select.Option key={`${option._id}`} value={`${option._id}`}>
        {option.countryName}
      </Select.Option>
    );
  };

  // getting list of fav sports name
  if (prevSports && prevSports.length > 0 && isEditMode) {
    prevSports = prevSports.map((sports) => ({
      ...sports,
      name: getSportsName(sports._id)?.name,
    }));
  }

  let title = "Create New Freelancer";
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultValues = {
    firstName: firstName || null,
    lastName: lastName || null,
    email: email || null,
    username: userName || null,
    password: null,
    contact_no: contactNumber || null,
    dob: dateOfBirth ? moment(dateOfBirth) : "",
    is_active: status || true,
    package_percentage: packagePercentage || 0,
    activity_percentage: activityPercentage || 0,
    slot_percentage: slotPercentage || 0,
    favorite_sports: _map(prevSports, (sport) => `${sport._id}@${sport.name}`),
    location: location || [],
    country_id: countryId ?? "6756c62dbd3e742dcd87359d",

  };

  // default values for freelancer venue
  const defaultVenue = {
    name: userName,
    business_id: null, // add user id after creating freelancer
    closing_time: moment().format(),
    is_active: status || true,
    latitude: 0,
    longitude: 0,
    location: {
      address: null,
      coordinates: [0, 0],
    },
    max_pitches: 1,
    opening_time: moment().format(),
    players_occupy: 10,
    sports_played: [],
    average_cost: 0,
    is_freelancer_venue: true,
  };

  const createVenue = (venueDetails) => {
    ///// now create default venue
    venuesService
      .createVenue(venueDetails)
      .then((response) => {
        if (response.success) {
          toast.success("New Venue Added Successfully!!");
          form.resetFields();
        } else {
          toast.warning("Something went wrong while updating!");
        }
      })
      .catch((error) => {
        console.log(error.response, "error.createVenue");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateVenue = (venue_id, venueDetails) => {
    // console.log(venueDetails, 'venueDetails')
    venuesService
      .updateVenue(venue_id, venueDetails)
      .then((response) => {
        if (response.success) {
          toast.success("Update Venue Successfully!!");
          form.resetFields();
        } else {
          toast.warning("Something went wrong while updating!");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getVenueByUserId = (bus_id, venueDetails) => {
    // now create default venue
    venuesService
      .fetchFreelanceVenueDetails(bus_id)
      .then((response) => {
        venueDetails.business_id = bussiness_id;
        if (response.docs && !response.docs.length) {
          createVenue(venueDetails);
        } else {
          updateVenue(response.docs[0]?._id, venueDetails);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [form, defaultValues]);

  const onFinish = (values) => {
    values.favorite_sports = getID(values.favorite_sports);
    const finalValues = { ...values };
    const address = values.location?.address;
    const lat = values.location?.coordinates?.lat;
    const lng = values.location?.coordinates?.lng;
console.log(values,'values')
    if (address && lat && lng) {
      finalValues.location = address;
      finalValues.latitude = lat;
      finalValues.longitude = lng;
      // ////////////////////for Venue
      defaultVenue.latitude = lat;
      defaultVenue.longitude = lng;
      defaultVenue.address = address;
      defaultVenue.location = {
        address: address,
        coordinates: [lat, lng],
      };
    } else {
      delete finalValues.location;
      delete finalValues.latitude;
      delete finalValues.longitude;
      delete defaultVenue.latitude;
      delete defaultVenue.longitude;
      delete defaultVenue.address;
      delete defaultVenue.location;
    }

    if (values.dob) finalValues.dob = moment(values.dob).format("YYYY/MM/DD");
    finalValues.favorite_sports = finalValues.favorite_sports
      ? sportsList.filter((sports) =>
          finalValues.favorite_sports.includes(sports._id)
        )
      : [];

    ////////////////////for Venue
    defaultVenue.name = `${finalValues.firstName} ${
      finalValues?.lastName ? finalValues.lastName : ""
    }`;
    defaultVenue.sports_played = values.favorite_sports;

    setLoading(true);
    if (isEditMode) {
      onClose();
      freelancerService
        .updateFreelancer(freelancerID, finalValues)
        .then((response) => {
          if (response.success) {
            //fetched venue for current freelancer
            getVenueByUserId(bussiness_id, defaultVenue);
            onReload();
            toast.success("Update Successful!!");
          } else {
            toast.warning("Something went wrong while updating!");
          }
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      freelancerService
        .createFreelancer(finalValues)
        .then((response) => {
          if (response.success) {
            const { data } = response;
            defaultVenue.business_id = data._id;
            createVenue(defaultVenue);
            toast.success("New Freelancer Added Successfully!!");
            form.resetFields();
          } else {
            toast.warning("Something went wrong while updating!");
          }
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  if (isEditMode) {
    title = "Edit Freelancer";
  }

  const checkUserName = (username) => {
    var regx = new RegExp(/^[a-z0-9_.]+$/);
    if (regx.test(username))
      freelancerService
        .checkUserName({ username })
        .then(({ success, data }) => {
          if (success) {
            if (data.exist) toast.warning("Username is already in use!");
            else toast.success("Username is available");
          } else toast.warning("Something went wrong to check username!");
        })
        .catch((error) => {
          console.log(error);
        });
  };


  return (
    <Drawer title={title} open={visible} onClose={onClose}>
      <div className={styles.container}>
        <Form
          initialValues={defaultValues}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              {
                required: true,
                message: "Please input freelancer first name!",
              },
            ]}
          >
            <Input placeholder="Enter freelancer first name" />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              {
                required: true,
                message: "Please input freelancer last name!",
              },
            ]}
          >
            <Input placeholder="Enter freelancer last name" />
            {/* <div className="subInputText">Venue name on map will be combination of name and lastname </div> */}
          </Form.Item>
          <Form.Item
            label="User Name"
            name="username"
            onBlur={(value) => checkUserName(value.target.value)}
            rules={[
              {
                required: true,
                pattern: new RegExp(/^[a-z0-9_.]+$/),
                message: `Usernames can only have:
                - Lowercase Letters (a-z)
                - Numbers (0-9)
                - Dots (.)
                - Underscores (_)!`,
              },
            ]}
          >
            <Input placeholder="Enter freelancer userName" />
          </Form.Item>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input placeholder="Enter e-mail" />
          </Form.Item>
          {!isEditMode && (
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Password placeholder="Enter password" className={styles.input} />
            </Form.Item>
          )}
          <Form.Item
            label="Contact No"
            name="contact_no"
            rules={[
              {
                required: true,
                message: "Please input freelancer contact number!",
              },
            ]}
          >
            <Input placeholder="Enter freelancer contact number" />
          </Form.Item>

          <Form.Item
            label="Address"
            name="location"
            rules={[
              { required: true, message: "Please type & select address" },
            ]}
          >
            <GooglePlacesAutoComplete
              lat={isEditMode ? latitude : defaultVenue?.latitude}
              lng={isEditMode ? longitude : defaultVenue?.longitude}
              place={isEditMode ? location : defaultVenue?.address}
            ></GooglePlacesAutoComplete>
          </Form.Item>

          <Form.Item
          name="country_id"
            label="Country"
            rules={[{ required: true, message: "Please select Country!" }]}
          >
            <Select
              mode="single"
              placeholder="Select Country"
              className={styles.select}
            >
              {_map(countryList, renderCountryOptions)}
            </Select>
          </Form.Item>

          <Form.Item
            name="favorite_sports"
            label="Sports Played"
            rules={[
              { required: true, message: "Please select sports played!" },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Select sports played"
              className={styles.select}
            >
              {_map(sportsList, renderSportsOptions)}
            </Select>
          </Form.Item>

          <Form.Item name="package_percentage" label="Package Percentage">
            <Input
              placeholder="Enter Package Percentage"
              type="number"
              min={0}
              max={99}
            />
          </Form.Item>
          <Form.Item name="activity_percentage" label="Activity Percentage">
            <Input
              placeholder="Enter Activity Percentage"
              type="number"
              min={0}
              max={99}
            />
          </Form.Item>
          <Form.Item
            name="slot_percentage"
            label="Calendar Activity Percentage"
          >
            <Input
              placeholder="Enter Calendar Activity Percentage"
              type="number"
              min={0}
              max={99}
            />
          </Form.Item>
          {/* <Form.Item
            name="is_active"
            label="Status"
            rules={[{ required: true, message: "Please select status!" }]}
          >
            <Select placeholder="select status" className={styles.select}>
              <Option value={true}>Active</Option>
              <Option value={false}>InActive</Option>
            </Select>
          </Form.Item> */}
          <Form.Item name="dob" label="Date of Birth">
            <PickerWithType type={"date"} format={"YYYY/MM/DD"} />
          </Form.Item>
          <Form.Item>
            <Button
              className={styles.saveButton}
              label={"Save"}
              type="primary"
              htmlType="submit"
              loading={loading}
            />
          </Form.Item>
        </Form>
      </div>
    </Drawer>
  );
}

CreateFreelancerForm.propTypes = {
  visible: PropTypes.bool,
  isEditMode: PropTypes.bool,
  onClose: PropTypes.func,
  sportsList: PropTypes.array,
};

CreateFreelancerForm.defaultProps = {
  visible: false,
  isEditMode: false,
  onClose: _noop,
};

export default CreateFreelancerForm;
